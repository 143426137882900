import { navigate } from "gatsby-link";
import React, { useState, useEffect } from "react";
import shapeBoxes from "../../images/shape_boxes.png";
import Faq from "../faq";
import {
  CarDetails,
  HomeServiceDetails,
  CustomerDetails,
  nigeriaStates,
} from "../form/utils";
import { ActionBanner } from "./utils";

const faqData = [
  {
    id: 1,
    title: "Why should I be a Carviva Autocare customer?",
    text:
      "Carviva is a platform that connects car users and car owners to a wide network of modern OEM workshops, garages and autocare service providers across Nigeria.",
  },
  {
    id: 2,
    title: "How flexible is Carviva Autocare pricing?",
    text:
      "With Carviva, at the tap of a button on a smart phone, you can buy fuel, buy engine oil, buy airtime/Data while service appointments such as car wash, car diagnostics, lube change, wheels balancing, alignment e.t.c can be booked and paid for on-the-go and from the comfort of homes and offices.",
  },
  {
    id: 3,
    title: "How do car owners pay for service?",
    text:
      "Car owners pay cash on service delivery at service center or via prepaid card payments before visiting service center.",
  },
];

const QuickServiceMidSection = () => {
  const [stage, setStage] = useState(1);
  const [states, setStates] = useState(null);
  const [allYears, setAllYears] = useState([]);
  const [allCars, setAllCars] = useState([]);
  const [carMakes, setCarMakes] = useState([]);
  const [carMake, setCarMake] = useState("");
  const [carModel, setCarModel] = useState("");
  const [carYear, setCarYear] = useState("");
  const [services, setServices] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [bookDate, setBookDate] = useState(
    new Date().toLocaleDateString("en-ca")
  );
  const [bookTime, setBookTime] = useState("10:30");
  const [custName, setCustName] = useState("");
  const [custPhone, setCustPhone] = useState("");
  const [custEmail, setCustEmail] = useState("");
  const [custState, setCustState] = useState("");
  const [custAddress, setCustAddress] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [provider, setProvider] = useState(null);
  const [paymentType, setPaymentType] = useState("Pay on delivery");

  const url = typeof window !== "undefined" ? window.location.href : "";

  useEffect(() => {
    constructYears();
    async function fetchStates() {
      const result = await nigeriaStates();
      setStates(result);
    }
    fetchStates();
    fetch(`${process.env.GATSBY_BASE_URL}vehicles`)
      .then((json) => json.json())
      .then((data) => {
        const tempVehicles = data.map((item) => {
          return { value: item, label: item };
        });
        setAllCars(tempVehicles);
      });

    fetch(`${process.env.GATSBY_API_URL}oil-change/list`)
      .then((json) => json.json())
      .then(({ data }) => {
        const tempServices = data.map((item) => {
          return {
            value: item.desc,
            label: item.desc,
          };
        });
        setServices(tempServices);
      });
  }, []);

  const constructYears = () => {
    let years = [];
    const currentYear = new Date().getFullYear();
    for (var year = currentYear - 21; year <= currentYear; year++) {
      years.push({ value: year, label: year });
    }
    setAllYears(years.reverse());
  };

  const handleCarMake = (selected) => {
    setCarMake(selected);
    fetch(`${process.env.GATSBY_BASE_URL}vehicles/${selected.value}`)
      .then((json) => json.json())
      .then((data) => {
        const sysm = data.map((item) => {
          return { value: item, label: item };
        });
        setCarMakes(sysm);
      });
  };

  const handleCarModel = (selected) => {
    setCarModel(selected);
  };

  const handleSelectedYear = (selected) => {
    setCarYear(selected);
  };

  const handleInputChange = (data, type) => {
    if (type === "name") {
      setCustName(data);
    } else if (type === "email") {
      setCustEmail(data);
    } else if (type === "phone") {
      setCustPhone(data);
    } else if (type === "address") {
      setCustAddress(data);
    }
  };

  const handleFormAddition = (nextPage) => {
    if (nextPage) {
      setStage(3);
    }
    // const data = {
    //   comment: comment === "" ? "no comment" : comment,
    //   car_system: selectedSystem.value,
    // };
    // setOrders((prevOrders) => [...prevOrders, data]);
    // setComment("");
    // setSelectedSystem(null);
    // if (nextPage) {
    //   setStage(3);
    // }
  };

  const handleServiceChange = async (selected) => {
    setSelectedService(selected);
    const rawResponse = await fetch(
      `${process.env.GATSBY_API_URL}oil-change/types`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ serviceName: selected.value }),
      }
    );
    const { data } = await rawResponse.json();
    const provider = data.filter((elem) => elem.provider_id === "276");
    setProvider(provider[0]);
  };

  const handlePaymentChange = ({ target: { value } }) => {
    setPaymentType(value);
  };

  const submitForm = async () => {
    setIsLoading(true);
    const payRef = Math.random()
      .toString(36)
      .substr(2, 15);
    const signData = {
      first_name: custName.split(" ")[0],
      last_name: custName.split(" ")[1],
      phone: custPhone.slice(1),
      email: custEmail,
      password: "carviva",
      onesignal: "",
      third_party: true,
    };
    const temp = await fetch(`${process.env.GATSBY_API_URL}signup`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(signData),
    });
    const signupData = await temp.json();
    const postData = {
      name: custName,
      email: custEmail,
      mobile: custPhone,
      user_id: signupData.user_id,
      centre_id: provider.centre_id,
      provider_id: provider.provider_id,
      service_id: provider.service_id,
      carModel: `${carMake.value} ${carModel.value}`,
      carYear: `${carYear.value}`,
      payType: paymentType,
      date: bookDate,
      time: bookTime,
      amount: provider.amount,
      quantity: 1,
      note: custAddress,
      ref: payRef,
      scope: provider.service_scope,
      service_info: {
        service_group: provider.service_group,
        service_desc: provider.service_desc,
      },
    };
    fetch(`${process.env.GATSBY_API_URL}booking/test`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          navigate("/confirmation/", {
            state: { ref: payRef },
            replace: true,
          });
        }
      })
      .catch((error) => {
        console.error("Form submit Error:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <div className="home-midsection">
        <ActionBanner
          page={url.includes("quickservice") ? "quickservice" : null}
        />
        <div className="form-section">
          <img src={shapeBoxes} alt="shapes" className="shapes" />
          <div className="container">
            <h2>Please Fill Quick Service Form</h2>
            <div className="form-steps">
              <div className={`form-step ${stage === 1 ? "active" : null}`}>
                <h3>1</h3>
                <span>
                  Car <br /> details
                </span>
              </div>
              <div className={`form-step ${stage === 2 ? "active" : null}`}>
                <h3>2</h3>
                <span>
                  Quick Service <br />
                  DETAILS
                </span>
              </div>
              <div className={`form-step ${stage === 3 ? "active" : null}`}>
                <h3>3</h3>
                <span>
                  Customer <br />
                  details
                </span>
              </div>
            </div>
          </div>
          <div className="container form-wrapper">
            <div className="form-level">
              <div className="form-level-bg"></div>
              <p>{stage}</p>
            </div>
            {stage === 1 && (
              <CarDetails
                cars={allCars}
                carMakes={carMakes}
                carMake={carMake}
                carModel={carModel}
                carYear={carYear}
                years={allYears}
                handleCarMake={handleCarMake}
                handleYear={handleSelectedYear}
                handleCarModel={handleCarModel}
                onSubmit={() => setStage(2)}
              />
            )}
            {stage === 2 && (
              <HomeServiceDetails
                date={bookDate}
                time={bookTime}
                services={services}
                service={selectedService}
                paymentType={paymentType}
                changeDate={(e) => setBookDate(e.target.value)}
                changeTime={(e) => setBookTime(e.target.value)}
                changeService={handleServiceChange}
                onSubmit={() => handleFormAddition(true)}
                scope={provider}
                handlePaymentChange={handlePaymentChange}
              />
            )}
            {stage === 3 && (
              <CustomerDetails
                name={custName}
                email={custEmail}
                phone={custPhone}
                state={custState}
                states={states}
                address={custAddress}
                handleInputChange={handleInputChange}
                changeState={(selected) => setCustState(selected)}
                isLoading={isLoading}
                onSubmit={submitForm}
                hasError={hasError}
                lagosOnly={true}
              />
            )}
          </div>
        </div>
      </div>
      <div className="trust form">
        <div className="container center">
          <p>
            Our Car Trouble Ticket service welcomes you to a world where car
            owners are able to connect to trusted and verified mechanics by
            logging a Ticket. Forget your car headaches, no more trial and
            errors; let's discuss your car issues and our expert mechanics will
            find a fix!
          </p>
        </div>
      </div>
      <Faq questions={faqData} title="Top questions from customers" />
    </>
  );
};

export default QuickServiceMidSection;
