import React from "react";
import "../components/style.scss";
import Helmet from "../components/layout/helmet";
import QuickServiceHeader from "../components/headers/quickservice";
import QuickServiceMidSection from "../components/contents/quickservice";
import Footer from "../components/layout/footer";

const QuickServicePage = () => (
  <div className="homepage quotes">
    <Helmet />
    <QuickServiceHeader />
    <QuickServiceMidSection />
    <Footer />
  </div>
);

export default QuickServicePage;
